import jsproj from "../jsproj.json";
import langjson from "../notes/lang.json";

export const url = "http://niravtech.com:5000/api/NotesApi";
// export const url = "https://localhost:7067/api/NotesApi";

export function getProject(projType) {
  if (projType === "javascript") {
    return [jsproj.language, jsproj.projects];
  }
}

export function getCategoryData(cat) {
  if (cat === "javascript") {
    console.log(langjson.javascript);
    var reader = new FileReader();
    var data = reader.readAsBinaryString(langjson.javascript);
    console.log(data);
  }
}
