import React from "react";
import Home from "./Home";
import Notes from "./Notes";
import Projects from "./Projects";
import Category from "./Category";
import NavArrows from "./NavArrows";
import NoteDisplay from "./NoteDisplay";
import ProjectDisplay from "./ProjectDisplay";
import { Routes, Route } from "react-router-dom";

const ContentBody = () => {
  return (
    <div id="contentbody">
      <NavArrows />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/notes/:category/:notes" element={<NoteDisplay />} />
        <Route path="/notes/:category" element={<Category />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projtype" element={<ProjectDisplay />} />
      </Routes>
    </div>
  );
};

export default ContentBody;
