import { url } from "./DataManager";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Notes = () => {
  const [data, setData] = useState(<div></div>);

  useEffect(() => {
    const getdata = async () => {
      let fetched = await fetch(url).then((response) => response.json());
      setData(fetched);
    };

    getdata();
  }, []);

  return (
    <div>
      <div className="flex-row">
        {data.length > 0 &&
          data.map((cat) => {
            return (
              <NavLink
                key={cat.key}
                to={cat.link}
                className="proj-name link-style"
              >
                {cat.name}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};

export default Notes;
