import React from "react";
import { getProject } from "./DataManager";
import ghicon from "../ghmarklight32px.png";
import { useParams } from "react-router-dom";

const ProjectDisplay = () => {
  const { projtype } = useParams();

  function addProjects(projType) {
    const projData = getProject(projType);
    const projTypeName = projData[0];
    const projects = projData[1];

    let box = projects.map((proj) => {
      return (
        <li key={proj.id}>
          <div className="flex-col font-color">
            <div className="flex-row">
              <a
                href={proj.url}
                className="proj-name link-style"
                target="_blank"
                rel="noreferrer"
              >
                {proj.projectname}
              </a>
              <a
                href={proj.git}
                className="flex-col jc-center"
                target="_blank"
                rel="noreferrer"
              >
                <img id="ghimg" src={ghicon} alt="GitHub Icon" />
              </a>
            </div>
            <div>{proj.text}</div>
          </div>
        </li>
      );
    });

    return (
      <React.Fragment>
        <div className="page-title">{projTypeName + " Projects"}</div>
        <ul>{box}</ul>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div id="projectdisplay">{addProjects(projtype)}</div>
    </React.Fragment>
  );
};

export default ProjectDisplay;
