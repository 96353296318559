import { url } from "./DataManager.js";
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

const Category = () => {
  const { category } = useParams();
  const [catData, setCatData] = useState([]);

  useEffect(() => {
    const getCatData = async () => {
      let catUrl = url + "/" + category;
      let fetched = await fetch(catUrl).then((response) => response.json());
      setCatData(fetched);
    };

    getCatData();
  }, [category]);

  return (
    <div className="flex-row">
      {catData.length > 0 &&
        catData.map((catdata) => {
          return (
            <NavLink
              key={catdata.id}
              to={"" + catdata.id}
              className="proj-name link-style"
            >
              {catdata.name}
            </NavLink>
          );
        })}
    </div>
  );
};

export default Category;
