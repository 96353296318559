import { url } from "./DataManager";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const NoteDisplay = () => {
  const { category, notes } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    const getNotes = async () => {
      const noteUrl = url + "/" + category + "/" + notes;
      const data = await fetch(noteUrl).then((res) => res.text());
      setData(data);
    };

    getNotes();
  }, [category, notes]);

  const processResults = () => {
    var parser = new DOMParser();
    var parsed = parser.parseFromString(data, "text/html");
    var div = parsed.getElementById("injected-note");

    var container = DOMPurify.sanitize(div);
    return <div dangerouslySetInnerHTML={{ __html: container }}></div>;
  };

  return <div>{processResults()}</div>;
};

export default NoteDisplay;
