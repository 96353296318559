import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";

const NavArrows = () => {
  const [arrows, setArrows] = useState([
    <NavLink key="1" to="/" className="proj-name arrow-link">
      /
    </NavLink>,
  ]);

  useEffect(() => {
    const root = document.getElementById("root");
    root.addEventListener(
      "click",
      (e) => {
        if (e.target.localName === "a") addArrow(e.target.pathname);
        return true;
      },
      true
    );
  }, []);

  const addArrow = (toLink) => {
    let arrows = [];
    let keycount = 1;

    arrows.push(
      <NavLink key={keycount} to="/" className="arrow-link">
        /
      </NavLink>
    );

    let split = toLink.split("/");
    split.shift();

    if (split.length === 1 && split[0] === "") {
      setArrows(arrows);
      return;
    }

    let url = "";

    for (let sym of split) {
      keycount++;
      if (url === "") url += sym;
      else url += "/" + sym;
      arrows.push(
        <NavLink key={keycount} to={url} className="arrow-link">
          {"/" + sym}
        </NavLink>
      );
    }

    setArrows(arrows);
  };

  return (
    <div id="navarrows" className="flex-row jc-center">
      {arrows.map((link) => {
        return link;
      })}
    </div>
  );
};

export default NavArrows;
