import React from "react";

const Home = () => {
  return (
    <div>
      Hello! My name is Nirav Patel and welcome to my professional portfolio
      page.
    </div>
  );
};

export default Home;
