import React from "react";
import { NavLink } from "react-router-dom";

const Projects = () => {
  return (
    <React.Fragment>
      <div className="flex-row">
        <NavLink to="javascript" className="proj-name link-style">
          JavaScript
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default Projects;
