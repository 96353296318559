import React from "react";
import lilogo from "../linkedinlogowhite.png";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <React.Fragment>
      <div id="banner" className="flex-row jc-space-around">
        <div id="logo" className="flex-row">
          <NavLink to="/" id="title">
            NiravTech
          </NavLink>
          <div id="reflinks" className="flex-col jc-center">
            <a
              id="linkedin"
              href="https://www.linkedin.com/in/nirav-r-patel/"
              className="flex-col jc-center"
              target="_blank"
              rel="noreferrer"
            >
              <img className="icon" src={lilogo} alt="LinkedIn Logo" />
            </a>
          </div>
        </div>
        <div id="navlinks" className="flex-row ai-center jc-space-between">
          <NavLink
            id="projects"
            to="/projects"
            className="navlink font-standard"
          >
            Projects
          </NavLink>
          <NavLink id="notes" to="/notes" className="navlink">
            Notes
          </NavLink>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner;
