import "./App.css";
import React from "react";
import Banner from "./lib/Banner";
import ContentBody from "./lib/ContentBody";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Banner />
      <ContentBody />
    </Router>
  );
};

export default App;
